/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import dynamic from '@wd/lowcode-pc/plugin/store/index.js'; 


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //
        theme:'',
        pageConfigs: [],
        testPaper: '',
        userInfo:JSON.parse(localStorage.getItem('userInfo')) || {},
        subUserInfo:JSON.parse(localStorage.getItem('subUserInfo')) || {},
        areaList:[]
    },
    mutations: {
        //主题
        setTheme(state, payload){
            state.theme = payload
        },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        // 账号级别
        saveUserInfo(state, payload) {
            state.userInfo = payload;
             //在线客服-客户信息扩展字段
            var subUserInfo = JSON.parse(localStorage.getItem('subUserInfo'));
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            var customField = {};
            if(subUserInfo || userInfo){
                customField = {
                    "姓名":localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    "手机号":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.phone) :userInfo.phone,
                    "邮箱":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.mail) : userInfo.mail,
                    "所在机构":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.branchName) :userInfo.branchName,
                  };
                window.qimoClientId = {
                    nickName:localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    userId : localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.accountId) : userInfo.accountId,
                    customField: JSON.stringify(customField)
                };
            }
        },
        updateUserInfo(state,payload){
            state.userInfo[payload.key] = payload.value;
            //在线客服-客户信息扩展字段
            var subUserInfo = JSON.parse(localStorage.getItem('subUserInfo'));
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            var customField = {};
            if(subUserInfo || userInfo){
                customField = {
                    "姓名":localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    "手机号":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.phone) :userInfo.phone,
                    "邮箱":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.mail) : userInfo.mail,
                    "所在机构":localStorage.getItem('subUserInfo') ? (subUserInfo && subUserInfo.branchName) :userInfo.branchName,
                };
                window.qimoClientId = {
                    nickName:localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.name) : userInfo.name,
                    userId : localStorage.getItem('subUserInfo') ?  (subUserInfo && subUserInfo.accountId) : userInfo.accountId,
                    customField: JSON.stringify(customField)
                };
            }
        },
        // 机构级别
        saveSubUserInfo(state, payload) {
            state.subUserInfo = payload;
        },
        updateSubUserInfo(state,payload){
            state.subUserInfo[payload.key] = payload.value
        },
        // 城市列表
        saveCityTree(state,payload){
            state.areaList = payload
        }
    },
    actions: {
    },
    getters: {
        // getConfigByPage(state) {
        //     return (module, pageName) => state.pageConfigs.find(
        //         page => page.moduleId === module && page.name === pageName,
        //     );
        // },
        // getConfigByPage(state) {
        //     return (path) => state.pageConfigs.find(
        //         page => page.uri.includes(path) 
        //     );
        // },
        // getConfigByPageNav(state) {
        //     return state.pageConfigs
        // }
    },
    modules: {
        app,
        pages,
        dynamic
    },
});
