<template>
<div class="industrycollege-header">
  <div class="industrycollegeNav-page-header">
    <div class="page-header-top">
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/edu-itc-fe-pc/Logo.png" alt="" @click="imgClick()">
    </div>
    <div class="page-header-nav">
      <div class="nav-name clearfix">
        <div class="nav-name-ul fl">
          <div
            v-for="(item, index) in inNavName"
            :key="index"
            class="nav-name-ul-li"
          >
            <span
              :class="isSelect === item ? 'active' : '' "
              @click="selectNav(item)"
              ref="navName"
            >
              {{ item.name }}

            </span>
            <svg-icon icon-class="arrow-drop-down-line" :className="isSelect === item ? 'active arrow' : 'arrow' " v-if="[4,5].includes(index)"/>
            <ul v-if="item.subList && item.subList.length > 0">
              <li
                v-for="(childItem, childIndex) in item.subList"
                @click="selectNav(childItem, item)"
                :key="childIndex"
                @mouseover.stop="changeOn(item,index)"
                @mouseout.stop="changeOut(item,index)"
              >
                <span>{{ childItem.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import inNavName  from '../js/industrycollege'
export default {
  name: "industrycollegeNav",
  components: {},
  data() {
    return {
      isSelect: null,
      inNavName:[],
      showOnTip1: false,
      showOnTip2: false,
      selectIndex:1,
    };
  },
  created() {
    this.inNavName = inNavName.inNavName
    if(this.inNavName.length > 0 && this.$route.path == '/'){
      this.isSelect = this.inNavName[0]
    }
    this.getActiveNav();
  },
  watch: {
    $route() {
      this.getActiveNav();
    }
  },
  methods: {
    imgClick(){
      this.$router.push({
        path:'/'
      })
    },
    getActiveNav() {
      console.log('this.inNavName',this.inNavName);
      this.inNavName.forEach(d => {
        if (d.url == this.$route.path) {
          this.isSelect = d;
        }else {
          if (d.subList) {
            d.subList.forEach(item => {
              if (item.url == this.$route.path) {
                this.isSelect = d;
              }
            });
          }
        }
      });
    },
    changeOn(item,index){
      if(this.isSelect === item){
        this.$refs.navName[index].className = 'active hover'
      }else{
        this.$refs.navName[index].className = 'hover'
      }
    },
    changeOut(item,index){
      if(this.isSelect === item){
        this.$refs.navName[index].className = 'active'
      }else{
        this.$refs.navName[index].className = ''
      }
    },
    selectNav(item, item1) {
        if (
          this.$route.path == item.url ||
          (item.name === "首页" &&
            (this.$route.path == "" || this.$route.path == "/" || this.$route.path == "/homepage"))
        ) {
          return;
        }
        if (item.url && item.url.indexOf("http") === -1) {
          if (item1) {
            this.isSelect = item1;
          }
          if (item.name === "首页") {
            this.$router.push({
              path: item.url
            });
            this.isSelect = item
          } else {
            this.$router.push({
              path: item.url,
              query: {
                name: item.url
              }
            });
          }
        }
    },
  }
};
</script>
<style lang="stylus" scoped >
.arrow{
  width:24px;
  height:24px;
  font-size 24px;
  position absolute;
  color:#333;
}
/deep/.el-button--primary span {
  font-size: 14px;
}

/deep/.el-dropdown-menu__item:focus, /deep/.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #2E5AD1;
  background: transparent;
}
  
/deep/.el-dropdown-menu__item {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

/deep/.el-dropdown {
  margin-top: 14px;
  margin-left: 10px;
}
.industrycollege-header{
    border-bottom:2px solid rgba(216,216,216,.45);
}
.industrycollegeNav-page-header {
  cursor: pointer;
  /* position: fixed; */
  width: 1200px;
  /* z-index: 666; */
  background: #fff;
  display flex;
  justify-content space-between;
  align-items center;
  margin:0 auto;
  padding-top:18px;
  padding-bottom:18px;
}
.industrycollegeNav-page-header .page-header-top img {
  width: 338px;
  height: 60px;
  object-fit: cover;
  --o-object-fit: cover;
}
.industrycollegeNav-page-header .page-header-top .right{
  display: flex;
  align-items: center;
}
.industrycollegeNav-page-header .page-header-top .right .span{
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
}
.industrycollegeNav-page-header .page-header-top .right .border{
  width: 2px;
  height: 16px;
  display: inline-block;
  background: rgba(102, 102, 102, 0.5);
  margin: 0 8px;
}
.industrycollegeNav-page-header .page-header-nav {
  display: flex;
  cursor: pointer;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li {
  display: inline-block;
  position: relative;
  padding:40px 25px;
  &:nth-last-child(1){
    padding-right 0;
  }
  &:nth-child(1){
    margin-right 14px;
    width:47px;
    padding-right :0;
    padding-left:0;
  }
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul {
  position: absolute;
  left: 50%;
  top: 72px;
  width: 134px;
  transform :translateX(-50%);
  display: none;
  z-index: 5;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1200);
  padding-top:4px;
  padding-bottom:4px;
  background #fff;
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover ul {
  display: block;
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li:hover {
  color:#245FFF;
  .arrow{
    color:#245FFF;
  }
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li {
  text-align: center;
  background: #FFFFFF;
  height: 38px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 38px;
  color:#333;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li span{
  font-size: 16px;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li:hover {
  color: #0256FF;
  background:#eee;
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li ul li.active {
  color: #0256FF;
  background:#eee;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li > span {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size:18px;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li > span:hover {
  color: #0256FF;
}

.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li > span:active {
  color: #0256FF;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .nav-name-ul-li .hover{
  color: #0256FF;
}
.industrycollegeNav-page-header .page-header-nav .nav-name .active {
  color:#0256FF !important;
}

.industrycollegeNav-page-header .page-header-nav .un-login {
  width: 90px;
  height: 28px;
  line-height: 28px;
  background: #FFFFFF;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #2E5AD1;
  margin-left: 10px;
  text-align: center;
  float: right;
}

.un-logosuccss {
  color: #FFFFFF;
  position: relative;
  height: 56px;
}

.un-logosuccss-head {
  margin-top: 12px;
  border-radius: 50%;
}

.un-logosuccss-main {
  margin: 7px 14px 0 11px;
}

.un-logosuccss-main p:first-child {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
}

.un-logosuccss-main p.headTop {
  line-height: 40px;
}

.un-logosuccss-main .userIdentity {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 58px;
  max-width: 130px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 2px;
  line-height: 20px;
  text-align: center;
  padding: 0 6px 0 4px;
  box-sizing: border-box;
}

.un-logosuccss-jt {
  margin-top: 25px;
}


.fixed-link {
  position: fixed;
  z-index: 4;
  bottom: 140px;
  right: 10px;
  background: #316FFF;
  border-radius: 8px;
  font-size: 16px;
  padding: 4px;
  width: 104px;
  box-sizing: border-box;
  text-align: center;

  .fixed-link-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;

    p {
      color: #fff;
    }

    img {
      width: 25px;
      margin-bottom: 12px;
    }

    button {
      padding: 0;

      /deep/span {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .fixed-link-bottom {
    background: #fff;
    color: #333333;
    line-height: 33px;
    border-radius: 8px;
    padding: 0 12px;

    div.item {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:nth-child(1) {
        border-bottom: 1px solid #F6F6F6;
      }

      p {
        line-height: 16px;
      }
    }

    img {
      width: 30px;
      margin: 0 auto 12px;
    }
  }
}

.fixed-link-bottom-service {
  width: 485px;
  height: 746px;
  position: fixed;
  bottom: 20px;
  right: 124px;
  z-index: 999;
}

.online-link {
  width: 268px;
  height: 275px;
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  right: 130px;
  bottom: 140px;
  text-align: center;
  border-radius: 8px;
  padding: 50px 0 28px;
  box-shadow: 0px 0px 8px 2px rgba(3, 3, 3, 0.06);
  border: 1px solid rgba(220, 215, 239, 0.33);
  z-index: 6;

  &::after {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 14px;
    height: 14px;
    background: #FFF;
    transform: rotate(45deg);
    z-index: 5;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
  }

  img {
    width: 140px;
    display: block;
    margin: 0 auto 20px;
  }

  div p {
    color: #666;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
