<template>
  <div id="app">
    <Header />
    <div
        ref="main"
        class="main"
      >
      <router-view :key='key' />
    </div>
    <Footer />
  </div>
</template>
<script>
// import Vue from 'vue'
import Header from "./view/head/crowd/module/industrycollegeNav.vue";
import Footer from "./view/head/crowd/module/footer.vue";
export default {
  data () {
    return {
     
    };
  },
  computed:{
    key() {
      return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
    },
  },
  components: { Header,Footer},
};
</script>
<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  background: #fff;
</style>
