import confUrl from '../../config/url';
export default API => ({
    getArticleInfo(data) {
        return API({
            url: `/cms/v1/article/getArticleInfo`,
            method: 'post',
            data
        });
    },
    getConfiguration(){
        return API({
            url: `/modular/admin/v1/configurations/${confUrl.confUrl}`,
        });
    }
})
