import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import API from '../api'
Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes,
});

function IsMobile(){
  let userAgent = navigator.userAgent,Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
  console.log('userAgent:',userAgent)
  return Agents.some((i)=>{
      return userAgent.includes(i)
  })
}

let frontendConfig = {}
function getLayoutSetting() {
  API.industrycollege.getConfiguration().then((data) => {
      console.log('frontendConfig',frontendConfig);
      console.log('data',data);
      //兼容版本写入localStorage start
        frontendConfig = data.data.frontendConfig.filter(item => {
          if (item.key === 'homepage') {
            return true;
          }
        })[0];
        localStorage.setItem('branchId', data.data.solutionInfo.defaultBranchId)
        localStorage.setItem('saasId', data.data.solutionInfo.rid)
        if (data.data.pageTitle) {
          document.title = data.data.pageTitle;
          localStorage.setItem('pageTitle', data.data.pageTitle)
        }
        router.beforeEach((to, from, next) => {
          console.log('32232332233223232',to,next);
          // 路由发生变化改变description和keyword
          const head  = document.getElementsByTagName('head')
          const meta = document.createElement('meta')
          if(to.meta.content){
            console.log('2222222222');
            document.querySelector('meta[name="keywords"]').setAttribute('content',to.meta.content.keywords)
            document.querySelector('meta[name="description"]').setAttribute('content',to.meta.content.description)
            meta.content = to.meta.content
            head[0].appendChild(meta)
          }else{
            console.log('33333333',document.querySelector('meta[name="keywords"]'));
            document.querySelector('meta[name="keywords"]').removeAttribute('content')
            document.querySelector('meta[name="description"]').removeAttribute('content')
          }
          //路由变化修改页面title
          if(to.meta.title){
            document.title = to.meta.title
          }else{
            document.title = ''
          }
          if(data.data.solutionInfo.domain){
            let _href = data.data.solutionInfo.domain.filter( item=> item.frontend_template_id === 4) //配置的路由
            let _hrefDomain =  _href[0].domain
            let isHomepage = data.data.frontendConfig.filter((item)=>{ return item.key ==='homepage'})[0].value; //首页路由
            console.log('isHomepage',isHomepage);
            let isAutoRedirect = JSON.parse(data.data.frontendConfig.filter((item)=>{ return item.key ==='is_auto_redirect'})[0].value); //是否需要pc跳转显示H5
            // if(to.path.split('/')[1] == isHomepage && IsMobile() && isAutoRedirect){
            //   window.location.href = `https://${_hrefDomain}`
            // } 
            if(IsMobile() && isAutoRedirect){
              window.location.href = `https://${_hrefDomain}`
            } else {
              next();
            }
          } else {
              next();
          }
        })
      //兼容版本写入localStorage end

    })
    .catch((error) => {
      console.log("error---", error);
    });

}
getLayoutSetting()

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
