<template>
  <div class="junminPersonalInfoForm">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px">
            <el-form-item label="真实姓名：" required>
                <el-input class="formItem" v-model="form.name" disabled placeholder="请填写真实姓名"></el-input>
            </el-form-item>
            <!-- <el-form-item class="uploadItem" label="照片：" prop="idPhoto">
                <el-upload
                    class="avatar-uploader"
                    ref="avatarUploader"
                    v-loading="uploadLoading"
                    @mouseenter.native="mouseoverUpload = true"
                    @mouseleave.native="mouseoverUpload = false"
                    :action="action"
                    :headers="headers"
                    :show-file-list="false"
                    :before-upload="handleAvatarBefore"
                    :on-success="handleAvatarSuccess"
                    :on-error="handleAvatarError">
                        <template v-if="form.idPhoto">
                            <img :src="form.idPhoto" class="avatarImg">
                            <div class="updateAvatarWrapper" v-if="mouseoverUpload">
                                <div class="updateAvatar">编辑照片</div>
                            </div>
                        </template>
                        <div class="uploader-icon" v-else-if="!uploadLoading">
                            <img src="@/assets/images/ic-tianjia@2x.png" alt="">
                        </div>
                </el-upload>
                <div class="tip">
                    <p>1、一寸正面免冠照片；支持jpg、jpeg、png格式；</p>
                    <p style="margin-top:6px">2、图片大小不超过4M</p>
                </div>
            </el-form-item> -->
            <el-form-item label="身份证号：" required>
                <el-input class="formItem" v-model="form.idCardNo" disabled placeholder="请填写身份证号"></el-input>
            </el-form-item>
            <!-- <el-form-item label="性别：" prop="gender">
                <el-radio-group v-model="form.gender">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="出生日期：" prop="birthday">
                <el-date-picker
                    v-model="form.birthday"
                    :picker-options="pickerOptions"
                    style="width:640px"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="民族：" prop="nation">
                <el-select class="formItem" v-model="form.nation" filterable placeholder="请选择选择民族">
                    <el-option v-for="(item,index) in nationList" :key="index" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="政治面貌：" prop="politics">
                <el-select class="formItem" v-model="form.politics" placeholder="请选择政治面貌">
                    <el-option v-for="item in politicsList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="籍贯：" class="jiguan" required>
                <el-form-item prop="hometownProvince" style="margin-bottom:0;display:inline-block;vertical-align:top">
                    <el-select v-model="form.hometownProvince" filterable placeholder="省" @change="provinceChange" style="width:315px">
                        <el-option v-for="item in hometownProvinceList" :key="item.id" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="hometownCity" style="margin-bottom:0;margin-left:10px;display:inline-block;vertical-align:top">
                    <el-select v-model="form.hometownCity" filterable placeholder="市" style="width:315px">
                        <el-option v-for="item in computedHometownCityList" :key="item.id" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
            </el-form-item> -->
            <el-form-item label="退役军人类别：" prop="userIdentity">
                <el-select class="formItem" v-model="form.userIdentity" placeholder="请选择退役军类别">
                    <el-option
                        v-for="(item,index) in userIdentityList"
                        :key="index"
                        :label="item"
                        :value="item">
                    </el-option>
                    <!-- <el-option label="计划安置军转干部" value="计划安置军转干部"></el-option>
                    <el-option label="自主择业军转干部" value="自主择业军转干部"></el-option>
                    <el-option label="政府安排工作退役士兵" value="政府安排工作退役士兵"></el-option>
                    <el-option label="自主就业退役士兵" value="自主就业退役士兵"></el-option> -->
                </el-select>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
                <el-input class="formItem" v-model="form.phone" :disabled="!isfirstEdit" placeholder="请填写手机号"></el-input>
            </el-form-item>
            <!-- <el-form-item label="微信号：" prop="wechat">
                <el-input class="formItem" v-model="form.wechat" placeholder="请填写微信号"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：" prop="mail">
                <el-input class="formItem" v-model="form.mail" placeholder="请输入电子邮箱"></el-input>
            </el-form-item>
            <el-form-item label="通讯地址：">
                <el-input class="formItem" v-model="form.address" placeholder="请填写通讯地址"></el-input>
            </el-form-item>
            <el-form-item label="学历：" prop="education" required>
                <el-select class="formItem" v-model="form.education" placeholder="请选择学历信息">
                    <el-option v-for="item in educationList" :key="item.key" :label="item.val" :value="item.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="毕业院校：">
                <el-input class="formItem" v-model="form.graduatedSchool"  placeholder="请输入毕业院校"></el-input>
            </el-form-item>
            <el-form-item label="所学专业：" place>
                <el-input class="formItem" v-model="form.major" placeholder="请输入所学专业"></el-input>
            </el-form-item>
            <el-form-item label="工作单位：">
                <el-input class="formItem" v-model="form.workUnit"  placeholder="请输入工作单位"></el-input>
            </el-form-item>
            <el-form-item label="职务：">
                <el-input class="formItem" v-model="form.position"  placeholder="请输入职务"></el-input>
            </el-form-item>
            <el-form-item label="职称：">
                <el-input class="formItem" v-model="form.jobTitle"  placeholder="请输入职称"></el-input>
            </el-form-item>
            <el-form-item label="单位电话：" prop="workPhone">
                <el-input class="formItem" v-model="form.workPhone"  placeholder="请输入单位电话"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="退役后去向：" prop="retiredAddress">
                <el-radio-group v-model="form.retiredAddress">
                    <el-radio :label="1">青岛市市区</el-radio>
                    <el-radio :label="2">青岛市区市</el-radio>
                    <el-radio :label="3">青岛以外</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <!-- <el-form-item label="退役后拟选择方向：" prop="retiredDirection">
                <el-radio-group v-model="form.retiredDirection">
                    <el-radio :label="1">就业</el-radio>
                    <el-radio :label="2">创业</el-radio>
                    <el-radio :label="3">其他</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <div class="footer">
                <div class="opeBtn1" @click="cancel">取消</div>
                <div class="opeBtn2" @click="onSubmit">保存</div>
            </div>
        </el-form>
  </div>
</template>

<script>
import config from '@/config/url'
export default {
    name:'personalInfoForm',
    data(){
        const isEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/;
        const isWorkPhone = /(^(\d{11})$|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/
        const emailValidator = (rule, value, callback) => {
            if(value && !isEmail.test(value)){
                 callback(new Error('请输入正确的电子邮箱'));
            }else{
                 callback();
            }
        }
        function phoneValidator(rule, value, callback) {
            if( value === '' ){
                callback(new Error('请填写手机号'));
            } else if ( !isPhone.test(value) ) {
                callback(new Error('请填写正确的手机号'));
            } else {
                callback();
            }
        }
        function workPhoneValidator(rule, value, callback) {
            if ( isWorkPhone.test(value) || (!value&&value!=='0') ) {
                callback();
            } else {
                callback(new Error('请填写正确的单位电话'));
            }
        }
        return{
            isfirstEdit:false,
            uploadLoading:false,
            mouseoverUpload:false,
            hometownProvinceList:[],//省
            educationList: [{key: 1, val: '初中及以下'}, {key: 2, val: '高中'},{key: 3, val: '大专'}, {key: 4, val: '本科'}, {key: 5, val: '硕士'}, {key: 6, val: '博士'}],
            nationList:['汉','满','蒙古','回','藏','维吾尔','苗','彝','壮','布依','侗','瑶','白','土家','哈尼','哈萨克','傣','黎','傈僳','佤','畲','高山','拉祜','水','东乡','纳西','景颇','柯尔克孜','土','达斡尔','仫佬','羌','布朗','撒拉','毛南','仡佬','锡伯','阿昌','普米','朝鲜','塔吉克','怒','乌孜别克','俄罗斯','鄂温克','德昂','保安','裕固','京','塔塔尔','独龙','鄂伦春','赫哲','门巴','珞巴','基诺'],
            politicsList:[{id:1,name:'中共党员'},{id:2,name:'中共预备党员'},{id:3,name:'共青团员'}],
            userIdentityList:[],
            form:{
                name:'',
                idPhoto:'',
                idCardNo:'',
                gender:1,
                birthday:'',
                nation:'',
                politics:'',//政治面貌
                hometownProvince:'',
                hometownCity:'',
                userIdentity:'',//退役军人类别
                phone:'',
                wechat:'',
                mail:'',
                address:'',
                education:'',
                graduatedSchool:'',
                major:'',//专业
                workUnit:'',//工作单位
                position:'',//职务
                jobTitle:'',//职称
                workPhone:'',
                retiredAddress:1,
                retiredDirection:1
            },
            // saveForm:{},
            rules:{
                idPhoto: [
                    { required: true, message: '请上传照片', trigger: 'change' }
                ],
                gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                birthday: [
                    { required: true, message: '请选择日期', trigger: 'blur' }
                ],
                nation: [
                    { required: true, message: '请选择选择民族', trigger: 'change' }
                ],
                politics: [
                    { required: true, message: '请选择政治面貌', trigger: 'change' }
                ],
                hometownProvince:[
                    { required: true, message: '请选择籍贯', trigger: 'change' }
                ],
                hometownCity: [
                    { required: true, message: '请选择籍贯', trigger: 'change' }
                ],
                userIdentity: [
                    { required: true, message: '请选择退役军类别', trigger: 'change' }
                ],
                education:[
                    { required: true, message: '请选择学历', trigger: 'change' }
                ],
                phone: [
                    { 
                        required: true, 
                        validator : phoneValidator,
                        trigger: 'blur' 
                    }
                ],
                wechat: [
                    { required: true, message: '请填写微信号', trigger: 'blur' }
                ],
                workPhone:  [
                    { 
                        validator : workPhoneValidator,
                        trigger: 'blur' 
                    }
                ],
                // retiredAddress: [
                //     { required: true, message: '请选择退役后去向' }
                // ],
                retiredDirection: [
                    { required: true, message: '请选择退役后拟选择方向' }
                ],
                mail: [
                    { validator: emailValidator, trigger: 'blur'}
                ]
            },
            action:config.API + '/resource/v1/resource/uploadImage',
            headers:{
                token:localStorage.getItem('token')
            },
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        }
    },
    computed:{
        computedHometownCityList(){
            if(!this.form.hometownProvince) return
            let province = this.hometownProvinceList.find(item => {
                return item.code === this.form.hometownProvince
            })
            return province && province.children
        }
    },
    created(){
        if(localStorage.getItem('checkJunminIsSaveInfo') == 'true') return
        this.$api.usercenter.checkSaveDetail().then(res => {
            if(!res.data){
                this.$message({
                    type:'error',
                    message:'请先完善个人资料',
                    duration: 2500
                })
            }else{
                localStorage.setItem('checkJunminIsSaveInfo',res.data)//军民资料是否完善
            }
      })
    },
    async mounted(){
        if(localStorage.getItem('checkJunminIsSaveInfo') === 'false' && this.form.phone === ''){
            this.isfirstEdit = true //首次编辑可修改手机号
        }
        await Promise.all([ this.getCityList(), this.getUserIdentityList()])
        this.getPersonalInfo()
    },
    methods:{
        provinceChange(){
            this.form.hometownCity = null
        },
        //获取城市列表
        // getTree(){
        //     return this.$api.usercenter.getTree().then(res => {
                
        //         res.data.forEach(item => {
        //             if(item.code.length != 2) return

        //             let cityList = []
                    
        //             item.children && item.children.length && item.children.forEach(el => {
        //                 let arr = ['北京市','天津市','重庆市','上海市']
        //                 if(arr.includes(item.name)){
        //                     el.children.forEach(elchildren => {
        //                         cityList.push({
        //                             name: elchildren.name,
        //                             code: elchildren.code,
        //                             id: elchildren.id
        //                         })
        //                     })
        //                 }else{
        //                     cityList.push({
        //                         name: el.name,
        //                         code: el.code,
        //                         id: el.id
        //                     })
        //                 }
        //             })
        //             let deRepeatArr = [...new Set(cityList)]

        //             this.hometownProvinceList.push({
        //                 name: item.name,
        //                 code: item.code,
        //                 id: item.id,
        //                 children:deRepeatArr
        //             })
                    
        //         })
        //     })
            
            
        // },
        getCityList(){
            return this.$api.usercenter.getCityList().then(res => {
                if(res.code == 0){
                    res.data.forEach(item => {
                        let cityList = []
                        
                        item.citys && item.citys.length && item.citys.forEach(el => {
                            cityList.push({
                                name: el.name,
                                code: el.code,
                                id: el.id
                            })
                        })

                        this.hometownProvinceList.push({
                            name: item.province.name,
                            code: item.province.code,
                            id: item.province.id,
                            children:cityList
                        })
                        
                    })
                }
                
            })
        },
        // 获取身份
        getUserIdentityList(){
            return this.$api.usercenter.getUserIdentityList().then(res => {
                if(res.success){
                    //res.data ["['a','b']"]
                    if(res.data && res.data.length>0){
                        let dataArr = JSON.parse(res.data[0])
                        this.userIdentityList = dataArr
                    }
                }
            })
        },
        getPersonalInfo(){
            this.$api.usercenter.findMyDetail().then(res => {
                if(res.code == 0){
                    this.form = res.data
                    
                    this.form.gender = res.data.gender == 0? null: res.data.gender
                    this.form.politics = res.data.politics == 0? null: res.data.politics
                    this.form.education = res.data.education == 0? null: res.data.education
                    // this.saveForm = {...this.form}//点击取消时回显

                    this.$refs.form.resetFields()

                    // 20220211-演示只保留姓名 身份证号 手机号 和退役军人类别四个必填项，其他必填给默认值
                    !this.form.idPhoto?this.form.idPhoto = "https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-pc/common/userL.png":null;
                    !this.form.gender?this.form.gender = 1:null;
                    !this.form.birthday? this.form.birthday ="2022-02-14 00:00:00" :null
                    !this.form.nation?this.form.nation = "汉":null;
                    !this.form.politics?this.form.politics = 1:null;
                    !this.form.hometownProvince?this.form.hometownProvince = "11":null;
                    !this.form.hometownCity?this.form.hometownCity = "1101":null;
                    !this.form.wechat?this.form.wechat = "无":null;
                    !this.form.education?this.form.education = 1:null;
                    !this.form.retiredDirection?this.form.retiredDirection = 1:null;
                    !this.form.retiredAddress?this.form.retiredAddress = 1:null;

                }
            })
        },
        handleAvatarBefore(file){
            this.uploadLoading = true
            let extension = file.name.split('.')[1]
            let formatList = ['jpg','jpeg','png','JPEG','PNG','JPG']
            let fileSize = file.size/1024/1024
            if(!formatList.includes(extension)){
                this.$message.error('请上传jpg、jpeg、png格式的照片')
                this.uploadLoading = false
                return false
            }
            if(fileSize>4){
                this.$message.error('照片大小不能大于4M')
                this.uploadLoading = false
                return false
            }
        },
        // 头像上传成功
        handleAvatarSuccess(res,file){
            this.uploadLoading = false
            this.form.idPhoto = file.response.data
            this.$refs.form.validateField('idPhoto')
        },
        handleAvatarError(){
            this.uploadLoading = false
            this.$message.error('头像上传失败')
        },
        cancel(){
            this.$router.push('/')
            // this.form = {...this.saveForm}
        },
        onSubmit(){ 
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$api.usercenter.saveMyDetail({...this.form}).then(res => {
                        if(res.code == 0){
                            this.$message.success('保存成功')
                            localStorage.setItem('checkJunminIsSaveInfo','true')
                            //保存手机号，更新用户信息
                            this.$store.commit('updateUserInfo',{key:'phone',value:this.form.phone})
                            localStorage.setItem('userInfo',JSON.stringify(this.$store.state.userInfo))
                            // if(this.$route.name === 'personalCenter'){
                            //     console.log("zxc在这里")
                            //     this.$router.push('/personalCenter')
                            // }else{
                                // this.$router.push('/')
                                // this.$router.push('/personalCenter')
                                sessionStorage.setItem('userCenterCurrentTab','我的学习')
                                location.reload(window.location.href)
                            // }
                        }
                    })
                } else {
                    this.$message.error('请检查各项信息填写是否正确')
                }
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
    .junminPersonalInfoForm
        width 822px
        margin 0 auto
    .formItem
        width 640px
    .el-form-item
        margin-bottom 16px
        /deep/ .el-form-item__label
            font-size 16px
            color #303133
        /deep/ .el-form-item__content
            line-height 40px
        /deep/ .el-radio__label
            font-size 16px
            color #303133
        /deep/ .el-input__inner
            border-radius 0
    .footer
        // margin-bottom 48px
        text-align center
        .opeBtn1,.opeBtn2
            display inline-block
            margin-top 38px
            width 100px
            height 40px
            font-size 16px
            color #ffffff
            text-align center
            line-height 40px
            cursor pointer
        .opeBtn1
            background #DCDCDC
        .opeBtn2
            margin-left 17px
            background #1972FF
    // 图片上传
    .uploadItem
        .tip 
            >p
                font-size 14px
                color #C1C1C1
                line-height 22px
    .avatar-uploader
        display inline-block
    .avatar-uploader /deep/ .el-upload 
        width: 130px
        height: 195px
        border: 1px solid #E6E6E6
        cursor: pointer
        position: relative
        overflow: hidden
    
    .uploader-icon 
        width: 36px
        height: 36px
        margin 0 auto
        margin-top 80px
        >img
            width 100%
            display block
            
    
    .avatarImg 
        width: 130px
        display: block
    .updateAvatarWrapper
        position absolute
        left 0
        bottom 0
        width 100%
        height 32px
        background rgba(255,255,255,.7)
        .updateAvatar
            margin 0 auto
            margin-top 5px
            width 64px
            height 22px
            border 1px solid #1972FF
            text-align center
            line-height 22px
            font-size 12px
            color #1972FF
</style>