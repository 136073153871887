export default [
    {
          path: '/jrwm',
          name: 'JoinUs',
          meta: {
            title: "加入我们_伟东云教育集团",
            login: false
          },
          component: () => import(/* webpackChunkName: 'JoinUs' */ './page/index.vue'),
    }
  ]