import Vue from 'vue';
import './libs/element';
import api from './api';
import App from './App.vue';
import router from './router';
import store from './store';
// import i18n from './locale';
import config from './config';
import Templates from './libs/template'
import Tools from '@stroll/tools'
import dayjs from 'dayjs';
import './styl/app.styl';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
config.productionTip = process.env.NODE_ENV === 'development';
import 'promise-polyfill/src/polyfill';
import dateFormat from './libs/dateFormat'; // 当前时间转换
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import SDK from '@wd/lowcode-pc'
import './assets/icons'
import AMap from 'vue-amap';
Vue.use(AMap);
AMap.initAMapApiLoader({
    key: "1e438335bfabb79eb8fbc1a8d169c3cf", 
    plugin: [
        "AMap.Geolocation",
        "AMap.Scale",
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.MapType",
        "AMap.PolyEditor",
        "AMap.CircleEditor",
    ],
    v:'1.4.4'
  });
Vue.prototype.resetSetItem = function (key, newVal) {
        if (key === 'signOutStorage') {
            // 创建一个StorageEvent事件
            var newStorageSignOut = document.createEvent('StorageEvent');
            const storage = {
                setItem: function (k, val) {
                    sessionStorage.setItem(k, val);
                    // 初始化创建的事件
                    newStorageSignOut.initStorageEvent('setItem', false, false, k, null, val, null, null);
                    // 派发对象
                    window.dispatchEvent(newStorageSignOut)
                }
            }
            return storage.setItem(key, newVal);
        }
    },
    /**
     * @description 全局注册应用配置
     */
    Vue.use(Templates)
Vue.use(VueVideoPlayer,
    /* {
  options: global default options,
  events: global videojs events
} */
)
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$dateFormat = dateFormat;

//新加配置代码
Vue.prototype.$store = store;
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer
//close


const BrowserLogger = require('alife-logger');
// BrowserLogger.singleton(conf) conf传入config配置。
// eslint-disable-next-line no-unused-vars
const bl = BrowserLogger.singleton({
    pid: 'g2t7afypx3@a71a3d04b8450ea',
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`http://arms-us-west-1.console.aliyun.com/r.png?`。
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    // enableLinkTrace:true,
    // enableApiCors: true,
    // 其他config配置。
});

//将自定义页面加入到路由中 路由必须是历史模式
Promise.all([SDK.setLayoutToRouter(router,store)]).then(() => {
    new Vue({
        store,
        router,
     render: h => h(App),
   }).$mount('#wdc-app')
   }).catch(res=>{
    console.log(res)
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#wdc-app');
});