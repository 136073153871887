
const urlName = '/wdn-portal-temp/v1'
export default API => ({
    // 我要申请保存
    save(data) {
        return API({
            url: `${urlName}/companyApplyTemp/save` ,
            method:  'post',
            data
        });
    }
})