const inNavName =[
    {
        name:'首页',
        url:'/',
    },
    {
        name:'研究院概况',
        url:'/yjygk'
    },
    {
        name:'新闻动态',
        url:'/xwdt'
    },
    {
        name:'成果展示',
        url:'/cgzs'
    },
    {
        name:'乡村振兴',
        url:'',
        subList:[
            {
                name:'乡村振兴大赛',
                url:'/xczxds',
            },
            {
                name:'专项精准扶贫',
                url:'/zxjzfp',
            }
        ]
    },
    {
        name:'产教融合',
        url:'',
        subList:[
            {
                name:'专业共建',
                url:'/zygj',
            },
            {
                name:'短期实训',
                url:'/dqsx',
            }
        ]
    },
    {
        name:'加入我们',
        url:'/jrwm'
    }
]
export default {
    inNavName
}