const Routers = require.context('./../modules/', true, /\.router\.js$/)

let RouterArr = [];

Routers.keys().forEach(key => {
  RouterArr = [
    ...RouterArr,
    ...Routers(key).default
  ]
})
const route = [
  ...RouterArr,
]

export default route;
