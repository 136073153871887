export default [
  {
        path: '/',
        name: 'industrycollegeHomepage',
        meta: {
          title: "国际互联网教育研究院",
          content:{
            keywords:"国际互联网教育研究院、伟东云教育、全球互联网教育平台运营商",
            description:"国际互联网教育研究院针对基础教育及职业教育、国际教育开展研究工作。本院定位为国家教育信息化政策的解读者、行业前沿的预测者，伟东云教育平台、产品及资源研发的指导者，国家和地方标准的参与者。"
          },
          login: false
        },
        component: () => import(/* webpackChunkName: 'industrycollege' */ './page/homepage.vue'),
  },
  {
    path: '/homepage',
    name: 'industrycollegeHomepage',
    meta: {
      title: "国际互联网教育研究院",
      content:{
        keywords:"国际互联网教育研究院、伟东云教育、全球互联网教育平台运营商",
        description:"国际互联网教育研究院针对基础教育及职业教育、国际教育开展研究工作。本院定位为国家教育信息化政策的解读者、行业前沿的预测者，伟东云教育平台、产品及资源研发的指导者，国家和地方标准的参与者。"
      },
      login: false
    },
    component: () => import(/* webpackChunkName: 'industrycollege' */ './page/homepage.vue'),
},
]